import React from 'react'

const TimeRead = ({ time }) => {
  return (
    <>
      <small style={{ whiteSpace: 'nowrap' }}>
        {time} {time === 1 ? 'min' : 'mins'} read
      </small>
    </>
  )
}

export default TimeRead
